import React from "react";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

import Content, { SidebarPanel } from "../components/Content";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PageWrapper from "../components/PageWrapper";
import Badge, { BadgeGroup } from "../components/Badge";
import Carousel from "../components/Carousel";

export default function Page({ data, location }) {
  const { markdownRemark } = data;
  const { frontmatter, html, excerpt } = markdownRemark;
  const {
    title,
    badges,
    carousel,
    sidebar_content,
    upper_sidebar_content,
  } = frontmatter;
  const isCarousel = Boolean(carousel.length);
  const resolveFirst = i => (i === 0 && badges.length ? "first" : "");

  return (
    <Layout>
      <SEO title={title} description={excerpt} pathname={location.pathname} />
      <PageWrapper>
        <Content>
          <div className="main" dangerouslySetInnerHTML={{ __html: html }} />
          <div className="sidebar">
            {upper_sidebar_content &&
              upper_sidebar_content.map(({ body }, i) => (
                <div
                  key={`${i}-upper-sidebar-content-${title}`}
                  className="upper-sidebar-container"
                >
                  <SidebarPanel className={i === 0 ? "first" : ""}>
                    <ReactMarkdown source={body} />
                  </SidebarPanel>
                </div>
              ))}
            {isCarousel ? (
              <div className="carousel-container">
                <Carousel hideBorder images={carousel} />
              </div>
            ) : null}
            <BadgeGroup>
              {badges &&
                badges.map((b, i) => (
                  <Badge
                    key={`${i}-badges-${title}`}
                    to={b.link.url}
                    headline={b.headline}
                    buttonText={b.link.title}
                    icon={b.icon}
                  />
                ))}
            </BadgeGroup>
            {sidebar_content &&
              sidebar_content.map(({ body }, i) => (
                <SidebarPanel
                  key={`${i}-sidebar-content-${title}`}
                  className={resolveFirst(i)}
                >
                  <ReactMarkdown source={body} />
                </SidebarPanel>
              ))}
          </div>
        </Content>
      </PageWrapper>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        title
        slug
        parent
        carousel {
          image
          alt
          credit {
            name
            url
          }
        }
        sidebar_content {
          body
        }
        upper_sidebar_content {
          body
        }
        badges {
          icon
          link {
            title
            url
          }
          headline
        }
      }
    }
  }
`;
